<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-6">
                <c-datepicker
                  :editable="false"
                  label="등록일자"
                  name="dailyResultDt"
                  v-model="data.dailyResultDt"
                />
              </div>
              <div class="col-6">
                <c-field
                  :editable="false"
                  :data="data"
                  deptValue="deptCd"
                  type="dept_user"
                  label="작성부서 & 작성자"
                  name="userId"
                  v-model="data.userId">
                </c-field>
              </div>
              <div class="col-4">
                <c-plant :required="true" :editable="false" type="edit" name="plantCd" v-model="data.plantCd" />
              </div>
              <div class="col-4">
                <c-select
                  :editable="false"
                  codeGroupCd="WEATHER_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="weatherCd"
                  label="날씨"
                  v-model="data.weatherCd"
                ></c-select>
              </div>
              <div class="col-4">
                <c-text
                  :editable="false"
                  label="온도"
                  suffix="℃"
                  name="temperature"
                  v-model="data.temperature">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <component :is="component1" :param.sync="popupParam" />
          <component :is="component2" :param.sync="popupParam" />
          <component :is="component3" :param.sync="popupParam" />
          <component :is="component4" :param.sync="popupParam" />
          <component :is="component5" :param.sync="popupParam" />
        </div>
      </div>
    </q-form>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'daily-air-report-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          plantCd: null,
          envAirDailyResultId: '',
          isFullScreen: false,
          dailyResultDt: '',
          approvalStatusCd: null,
          approvalTarget: false,
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      editable: false,
      component1: null, // 콤포넌트
      component2: null, // 콤포넌트
      component3: null, // 콤포넌트
      component4: null, // 콤포넌트
      component5: null, // 콤포넌트
      data: {
        plantCd: null,  // 사업장코드
        envAirDailyResultId: '',  // 대기일지 일련번호
        dailyResultDt: '',  // 작성일
        deptCd: '',  // 작성부서
        userId: '',  // 작성자
        weatherCd: null,  // 날씨
        temperature: '',  // 온도
      },
    };
  },

  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.detailUrl = selectConfig.env.air.daily.result.get.url;
      this.getDetail();
      
    },
    getDetail() {
      if (this.popupParam.envAirDailyResultId) {
        this.$http.url = this.detailUrl;
        this.$http.param = {envAirDailyResultId: this.popupParam.envAirDailyResultId}
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.data = _result.data;
          this.popupParam.plantCd = this.data.plantCd;
          this.popupParam.dailyResultDt = this.data.dailyResultDt;
          this.component1 = () => import(`${'./dailyAirReport01.vue'}`);
          this.component2 = () => import(`${'./dailyAirReport02.vue'}`);
          this.component3 = () => import(`${'./dailyAirReport03.vue'}`);
          this.component4 = () => import(`${'./dailyAirReport04.vue'}`);
          this.component5 = () => import(`${'./dailyAirReport05.vue'}`);
        },);
      }
    },
  }
};
</script>